import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080 1080" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >
				<path fill="#00ADEF" opacity="1.000000" stroke="none" 
	d="
M499.000000,1081.000000 
	C332.685791,1081.000000 166.871613,1081.000000 1.028704,1081.000000 
	C1.028704,721.060669 1.028704,361.121368 1.028704,1.091017 
	C360.897766,1.091017 720.795532,1.091017 1080.846680,1.091017 
	C1080.846680,360.999939 1080.846680,720.999939 1080.846680,1081.000000 
	C887.120911,1081.000000 693.310486,1081.000000 499.000000,1081.000000 
M552.138855,354.176392 
	C525.971985,352.860535 500.809998,356.516235 478.215790,370.677094 
	C471.662781,374.784180 463.896149,376.922546 458.784790,383.739166 
	C455.230743,388.478973 449.030029,391.196869 445.299347,395.850281 
	C439.748474,402.774231 435.113281,410.475006 430.480621,418.083954 
	C418.841614,437.200653 414.259796,458.322235 414.194397,480.414215 
	C414.167633,489.461395 415.221161,498.587219 416.650574,507.538757 
	C417.743408,514.382629 419.431183,521.347412 422.211945,527.660400 
	C427.878784,540.525513 434.296265,552.973694 443.748108,563.778259 
	C451.881317,573.075500 460.755554,581.222412 471.175079,587.796509 
	C480.870544,593.913818 490.487457,600.012268 502.069031,602.203491 
	C507.284302,603.190186 512.231445,606.073364 517.437683,606.530273 
	C528.799866,607.527466 540.310974,608.420166 551.645752,607.651855 
	C561.312622,606.996704 570.766052,603.642639 580.409363,602.066101 
	C590.433533,600.427307 598.920532,595.380859 607.159119,590.206360 
	C614.482239,585.606750 621.507263,580.098938 627.453186,573.841492 
	C636.492615,564.328674 645.039917,554.219666 652.690002,543.569275 
	C656.533447,538.218567 657.858398,531.082214 660.496216,524.826721 
	C661.214294,523.123718 663.191040,521.804077 663.507874,520.106567 
	C664.530090,514.629211 664.946289,509.042206 665.787903,503.526764 
	C666.679443,497.683777 668.384888,491.899048 668.628601,486.042816 
	C668.845947,480.821442 667.240173,475.552246 666.987305,470.277832 
	C666.320557,456.377350 663.741821,442.923370 657.438904,430.469574 
	C652.986389,421.671997 648.620178,412.633026 642.738831,404.812225 
	C636.919250,397.073578 629.622742,390.299316 622.295105,383.876740 
	C612.442200,375.240814 601.800720,367.925415 589.147766,363.256683 
	C577.340271,358.899872 565.304077,356.376373 552.138855,354.176392 
M334.527710,705.061218 
	C339.502167,704.784302 344.502228,704.738403 349.446716,704.190735 
	C371.552002,701.742188 382.383911,689.060242 381.139374,666.945129 
	C380.392548,653.674011 373.661133,644.581299 361.228577,639.691467 
	C350.654694,635.532593 339.575226,636.807007 328.649109,636.514282 
	C322.244904,636.342651 321.736298,636.948059 321.728180,643.240356 
	C321.706635,659.906128 321.762543,676.572021 321.713654,693.237610 
	C321.672974,707.113342 321.408630,704.894043 334.527710,705.061218 
M758.987671,687.585083 
	C759.085571,685.098999 759.275757,682.612488 759.266357,680.126831 
	C759.202698,663.378723 745.050293,653.397034 729.225037,658.745972 
	C727.418823,659.356567 725.065063,659.316467 723.239197,658.727966 
	C716.199585,656.458984 713.152893,658.441040 713.152100,665.702820 
	C713.150208,683.166199 713.151794,700.629639 713.155579,718.093018 
	C713.157104,725.224548 713.738464,725.656494 719.514771,725.934326 
	C725.715515,726.232544 724.961487,722.190063 724.907166,718.309692 
	C724.845886,713.934448 724.893005,709.557678 724.893005,706.556213 
	C732.676025,705.592957 739.596497,705.739502 745.798218,703.700073 
	C752.649414,701.447021 757.109314,695.758911 758.987671,687.585083 
M599.962097,677.501343 
	C599.975952,691.983948 600.074951,706.467346 599.955139,720.948792 
	C599.915894,725.697754 602.864746,725.769104 606.250061,725.824890 
	C609.681152,725.881409 612.071960,725.266296 611.846924,720.923035 
	C611.576965,715.712341 611.783142,710.476990 611.783142,704.973389 
	C613.534851,705.065552 614.392151,704.943848 615.140747,705.174683 
	C630.474243,709.902405 647.602661,698.612671 646.281921,679.441162 
	C645.207275,663.842957 631.749634,653.746460 616.674866,658.452271 
	C614.539917,659.118774 611.905518,659.063171 609.713135,658.508484 
	C602.311218,656.635864 600.000244,658.294800 600.002991,666.015320 
	C600.004211,669.511047 599.976562,673.006836 599.962097,677.501343 
M526.651184,647.702637 
	C530.813538,647.680725 534.976196,647.614868 539.138062,647.649231 
	C543.371765,647.684265 543.872498,644.922729 543.927673,641.622437 
	C543.980652,638.456360 542.926941,636.479004 539.407227,636.480469 
	C523.422729,636.487366 507.438019,636.501099 491.453827,636.426697 
	C487.267975,636.407288 486.676239,638.748596 486.689850,642.251831 
	C486.703186,645.680054 487.599396,647.714661 491.397125,647.649475 
	C495.391907,647.580933 499.400360,647.839539 503.382111,647.604248 
	C507.791290,647.343689 509.113373,649.201721 509.055389,653.486694 
	C508.857239,668.137024 508.965851,682.791809 509.009888,697.444763 
	C509.032654,705.012573 509.187622,705.139404 515.333984,705.110474 
	C521.197083,705.082886 521.197876,705.082886 521.204834,697.721313 
	C521.218872,682.901794 521.247925,668.082275 521.232727,653.262756 
	C521.229553,650.161865 521.532349,647.437622 526.651184,647.702637 
M388.993134,687.384949 
	C386.587372,696.865662 389.953613,703.592163 399.436340,705.221863 
	C404.498535,706.091858 410.027588,704.122131 415.362274,703.601135 
	C417.075348,703.433899 418.849091,703.731445 420.580322,703.939148 
	C424.066925,704.357605 427.546265,705.152039 431.021881,705.117493 
	C431.861816,705.109192 433.195557,702.532227 433.357483,701.034302 
	C433.524445,699.489685 432.706696,696.505005 432.030762,696.418945 
	C427.586121,695.852600 427.923096,692.634949 427.838043,689.704163 
	C427.664276,683.716980 427.833771,677.716125 427.532227,671.737488 
	C427.241058,665.964050 424.478180,661.476013 418.998993,659.294006 
	C411.439240,656.283325 403.729126,656.592896 396.090851,659.202332 
	C392.213684,660.526855 391.008148,663.489441 392.118683,667.134094 
	C393.417542,671.396667 396.526184,668.924866 399.153534,668.759888 
	C403.367340,668.495483 407.832092,667.973511 411.750427,669.096680 
	C413.637665,669.637634 414.520386,673.682983 415.858002,676.141479 
	C413.821350,676.618713 411.786957,677.492004 409.747650,677.503662 
	C401.502991,677.550720 393.661377,678.244934 388.993134,687.384949 
M539.695740,678.829651 
	C539.541382,694.278198 545.930054,703.071411 559.890503,705.649902 
	C570.173584,707.549072 579.002441,704.383484 585.478394,695.969360 
	C591.221130,688.507874 590.859253,679.862732 587.988220,671.753967 
	C584.653259,662.334900 572.688171,656.000916 562.930847,657.163635 
	C549.691284,658.741211 541.764832,665.840149 539.695740,678.829651 
M441.699219,682.499756 
	C441.693146,688.498291 441.825256,694.501099 441.637787,700.493958 
	C441.484955,705.380249 444.656372,705.092529 447.966736,705.181763 
	C451.296570,705.271423 453.310455,704.622314 453.183197,700.556824 
	C452.943420,692.900024 453.102570,685.229614 453.153564,677.565247 
	C453.188629,672.299561 458.583984,667.810364 464.719849,667.853882 
	C470.407806,667.894226 473.081268,670.784668 473.136200,677.160583 
	C473.205109,685.157898 473.206635,693.156189 473.171844,701.153809 
	C473.150452,706.074890 476.760559,704.909302 479.575317,705.071716 
	C482.409729,705.235107 484.966644,705.209412 484.916840,701.144409 
	C484.800537,691.648621 485.193420,682.137451 484.797943,672.656738 
	C484.337097,661.608459 475.772400,655.362427 464.820740,657.827759 
	C461.172150,658.649109 457.704987,660.276672 453.935883,661.613342 
	C452.029694,657.529053 448.323975,657.616394 444.681305,658.496643 
	C440.477814,659.512390 441.851807,663.216309 441.768646,666.005066 
	C441.614685,671.166077 441.708862,676.334595 441.699219,682.499756 
M662.036621,702.488220 
	C670.622986,708.539062 678.972839,706.466797 687.311401,701.532227 
	C687.797363,702.115784 688.125610,702.916138 688.616089,703.032471 
	C691.665222,703.755432 694.762024,704.737000 697.815918,704.652283 
	C698.566895,704.631409 699.785828,700.977661 699.819458,698.977661 
	C700.015076,687.334717 699.766785,675.683716 700.006592,664.042358 
	C700.094727,659.763428 698.817810,658.015320 694.369873,658.114197 
	C690.001648,658.211426 687.976929,659.630859 688.152771,664.288757 
	C688.410034,671.100098 688.201721,677.929260 688.184326,684.750793 
	C688.166870,691.589600 684.121704,695.462769 676.950134,695.519287 
	C670.963074,695.566406 668.279663,692.339478 668.209595,684.848999 
	C668.142639,677.695679 667.973633,670.534973 668.218140,663.390137 
	C668.356750,659.338928 666.688171,658.039612 662.918518,658.201477 
	C659.498657,658.348267 656.613953,658.537964 656.708191,663.221252 
	C656.878662,671.702637 656.199707,680.236694 656.931763,688.656921 
	C657.327393,693.208008 659.907288,697.569153 662.036621,702.488220 
z"/>
<path fill="#32AAD4" opacity="1.000000" stroke="none" 
	d="
M552.598145,354.215454 
	C565.304077,356.376373 577.340271,358.899872 589.147766,363.256683 
	C601.800720,367.925415 612.442200,375.240814 622.295105,383.876740 
	C629.622742,390.299316 636.919250,397.073578 642.738831,404.812225 
	C648.620178,412.633026 652.986389,421.671997 657.438904,430.469574 
	C663.741821,442.923370 666.320557,456.377350 666.987305,470.277832 
	C667.240173,475.552246 668.845947,480.821442 668.628601,486.042816 
	C668.384888,491.899048 666.679443,497.683777 665.787903,503.526764 
	C664.946289,509.042206 664.530090,514.629211 663.507874,520.106567 
	C663.191040,521.804077 661.214294,523.123718 660.496216,524.826721 
	C657.858398,531.082214 656.533447,538.218567 652.690002,543.569275 
	C645.039917,554.219666 636.492615,564.328674 627.453186,573.841492 
	C621.507263,580.098938 614.482239,585.606750 607.159119,590.206360 
	C598.920532,595.380859 590.433533,600.427307 580.409363,602.066101 
	C570.766052,603.642639 561.312622,606.996704 551.645752,607.651855 
	C540.310974,608.420166 528.799866,607.527466 517.437683,606.530273 
	C512.231445,606.073364 507.284302,603.190186 502.069031,602.203491 
	C490.487457,600.012268 480.870544,593.913818 471.175079,587.796509 
	C460.755554,581.222412 451.881317,573.075500 443.748108,563.778259 
	C434.296265,552.973694 427.878784,540.525513 422.211945,527.660400 
	C419.431183,521.347412 417.743408,514.382629 416.650574,507.538757 
	C415.221161,498.587219 414.167633,489.461395 414.194397,480.414215 
	C414.259796,458.322235 418.841614,437.200653 430.480621,418.083954 
	C435.113281,410.475006 439.748474,402.774231 445.299347,395.850281 
	C449.030029,391.196869 455.230743,388.478973 458.784790,383.739166 
	C463.896149,376.922546 471.662781,374.784180 478.215790,370.677094 
	C500.809998,356.516235 525.971985,352.860535 552.598145,354.215454 
M625.448486,390.034515 
	C623.615967,388.341278 621.888794,386.514435 619.935120,384.974884 
	C592.869141,363.646393 562.370972,353.747040 527.807373,357.319580 
	C496.257629,360.580597 469.667908,374.209900 448.560852,397.434723 
	C423.244537,425.291107 412.873199,458.768311 417.174591,496.070923 
	C420.842133,527.876770 435.122498,554.588440 459.678223,575.575256 
	C487.707977,599.531128 520.234863,609.166626 556.518860,604.705322 
	C589.826233,600.610168 617.063232,584.891479 638.229248,558.595520 
	C654.216064,538.734131 663.105347,516.024170 664.790100,490.891388 
	C667.421570,451.636383 654.526794,417.918823 625.448486,390.034515 
z"/>
<path fill="#EDFCFC" opacity="1.000000" stroke="none" 
	d="
M334.035461,705.061218 
	C321.408630,704.894043 321.672974,707.113342 321.713654,693.237610 
	C321.762543,676.572021 321.706635,659.906128 321.728180,643.240356 
	C321.736298,636.948059 322.244904,636.342651 328.649109,636.514282 
	C339.575226,636.807007 350.654694,635.532593 361.228577,639.691467 
	C373.661133,644.581299 380.392548,653.674011 381.139374,666.945129 
	C382.383911,689.060242 371.552002,701.742188 349.446716,704.190735 
	C344.502228,704.738403 339.502167,704.784302 334.035461,705.061218 
M334.046692,689.100159 
	C333.604553,693.674805 336.186127,695.041260 340.159393,694.679138 
	C343.631470,694.362671 347.138855,694.084900 350.535309,693.349731 
	C363.483521,690.547241 370.810272,681.091736 369.771698,668.804932 
	C368.707703,656.216858 359.730530,648.122559 346.276581,647.658325 
	C344.116638,647.583801 341.938019,647.802124 339.792480,647.610840 
	C335.566101,647.234131 333.880859,648.780701 333.978088,653.250427 
	C334.231171,664.889221 334.057312,676.537354 334.046692,689.100159 
z"/>
<path fill="#E8FBFC" opacity="1.000000" stroke="none" 
	d="
M758.872437,687.983521 
	C757.109314,695.758911 752.649414,701.447021 745.798218,703.700073 
	C739.596497,705.739502 732.676025,705.592957 724.893005,706.556213 
	C724.893005,709.557678 724.845886,713.934448 724.907166,718.309692 
	C724.961487,722.190063 725.715515,726.232544 719.514771,725.934326 
	C713.738464,725.656494 713.157104,725.224548 713.155579,718.093018 
	C713.151794,700.629639 713.150208,683.166199 713.152100,665.702820 
	C713.152893,658.441040 716.199585,656.458984 723.239197,658.727966 
	C725.065063,659.316467 727.418823,659.356567 729.225037,658.745972 
	C745.050293,653.397034 759.202698,663.378723 759.266357,680.126831 
	C759.275757,682.612488 759.085571,685.098999 758.872437,687.983521 
M739.465454,695.567993 
	C740.337891,695.098999 741.284119,694.729309 742.071045,694.145142 
	C747.830017,689.869202 749.712891,681.681763 746.544556,674.894775 
	C743.740845,668.888611 737.483948,666.260803 730.109497,668.070740 
	C726.957214,668.844421 724.726013,670.368591 724.863953,674.183044 
	C725.031738,678.821228 724.882507,683.470825 724.874146,688.115479 
	C724.863525,693.981445 726.479553,695.862488 732.242310,696.216187 
	C734.364014,696.346436 736.518372,695.945312 739.465454,695.567993 
z"/>
<path fill="#EAFBFC" opacity="1.000000" stroke="none" 
	d="
M599.962097,677.001953 
	C599.976562,673.006836 600.004211,669.511047 600.002991,666.015320 
	C600.000244,658.294800 602.311218,656.635864 609.713135,658.508484 
	C611.905518,659.063171 614.539917,659.118774 616.674866,658.452271 
	C631.749634,653.746460 645.207275,663.842957 646.281921,679.441162 
	C647.602661,698.612671 630.474243,709.902405 615.140747,705.174683 
	C614.392151,704.943848 613.534851,705.065552 611.783142,704.973389 
	C611.783142,710.476990 611.576965,715.712341 611.846924,720.923035 
	C612.071960,725.266296 609.681152,725.881409 606.250061,725.824890 
	C602.864746,725.769104 599.915894,725.697754 599.955139,720.948792 
	C600.074951,706.467346 599.975952,691.983948 599.962097,677.001953 
M619.658203,696.297791 
	C620.324097,696.307312 620.990173,696.332092 621.655884,696.324097 
	C627.725403,696.251099 631.647095,693.034485 633.803833,687.628296 
	C636.167053,681.704468 634.591125,674.703674 630.139160,670.595154 
	C625.782410,666.574524 620.533325,666.945862 615.602112,668.681213 
	C613.876099,669.288574 612.164124,672.197083 611.902954,674.240967 
	C611.337585,678.665527 612.000549,683.234253 611.630676,687.700012 
	C611.178955,693.153259 613.409485,695.875916 619.658203,696.297791 
z"/>
<path fill="#ECFCFC" opacity="1.000000" stroke="none" 
	d="
M526.163940,647.715698 
	C521.532349,647.437622 521.229553,650.161865 521.232727,653.262756 
	C521.247925,668.082275 521.218872,682.901794 521.204834,697.721313 
	C521.197876,705.082886 521.197083,705.082886 515.333984,705.110474 
	C509.187622,705.139404 509.032654,705.012573 509.009888,697.444763 
	C508.965851,682.791809 508.857239,668.137024 509.055389,653.486694 
	C509.113373,649.201721 507.791290,647.343689 503.382111,647.604248 
	C499.400360,647.839539 495.391907,647.580933 491.397125,647.649475 
	C487.599396,647.714661 486.703186,645.680054 486.689850,642.251831 
	C486.676239,638.748596 487.267975,636.407288 491.453827,636.426697 
	C507.438019,636.501099 523.422729,636.487366 539.407227,636.480469 
	C542.926941,636.479004 543.980652,638.456360 543.927673,641.622437 
	C543.872498,644.922729 543.371765,647.684265 539.138062,647.649231 
	C534.976196,647.614868 530.813538,647.680725 526.163940,647.715698 
z"/>
<path fill="#E7FBFC" opacity="1.000000" stroke="none" 
	d="
M389.101135,687.032471 
	C393.661377,678.244934 401.502991,677.550720 409.747650,677.503662 
	C411.786957,677.492004 413.821350,676.618713 415.858002,676.141479 
	C414.520386,673.682983 413.637665,669.637634 411.750427,669.096680 
	C407.832092,667.973511 403.367340,668.495483 399.153534,668.759888 
	C396.526184,668.924866 393.417542,671.396667 392.118683,667.134094 
	C391.008148,663.489441 392.213684,660.526855 396.090851,659.202332 
	C403.729126,656.592896 411.439240,656.283325 418.998993,659.294006 
	C424.478180,661.476013 427.241058,665.964050 427.532227,671.737488 
	C427.833771,677.716125 427.664276,683.716980 427.838043,689.704163 
	C427.923096,692.634949 427.586121,695.852600 432.030762,696.418945 
	C432.706696,696.505005 433.524445,699.489685 433.357483,701.034302 
	C433.195557,702.532227 431.861816,705.109192 431.021881,705.117493 
	C427.546265,705.152039 424.066925,704.357605 420.580322,703.939148 
	C418.849091,703.731445 417.075348,703.433899 415.362274,703.601135 
	C410.027588,704.122131 404.498535,706.091858 399.436340,705.221863 
	C389.953613,703.592163 386.587372,696.865662 389.101135,687.032471 
M408.416443,696.607544 
	C410.194427,696.264648 412.360199,696.425842 413.643036,695.439148 
	C414.964325,694.422729 415.973663,692.327576 416.096436,690.630371 
	C416.202576,689.163086 414.934998,686.317261 414.169312,686.280579 
	C410.192505,686.090149 406.100708,686.236023 402.231110,687.090027 
	C401.018341,687.357727 399.419556,690.391235 399.713196,691.801453 
	C400.062836,693.480652 402.087128,695.027771 403.720642,696.140808 
	C404.679962,696.794434 406.290344,696.492493 408.416443,696.607544 
z"/>
<path fill="#E8FBFC" opacity="1.000000" stroke="none" 
	d="
M539.702881,678.394897 
	C541.764832,665.840149 549.691284,658.741211 562.930847,657.163635 
	C572.688171,656.000916 584.653259,662.334900 587.988220,671.753967 
	C590.859253,679.862732 591.221130,688.507874 585.478394,695.969360 
	C579.002441,704.383484 570.173584,707.549072 559.890503,705.649902 
	C545.930054,703.071411 539.541382,694.278198 539.702881,678.394897 
M561.307190,695.620422 
	C562.967346,695.662476 564.633301,695.814209 566.286682,695.727234 
	C572.987244,695.375183 577.963379,689.619873 578.076721,682.179443 
	C578.198364,674.193787 573.593323,668.385254 566.680054,667.804504 
	C559.517151,667.202759 552.724731,671.417053 551.365356,677.755493 
	C550.101013,683.650452 551.118652,692.041077 561.307190,695.620422 
z"/>
<path fill="#E9FCFC" opacity="1.000000" stroke="none" 
	d="
M441.699463,681.999878 
	C441.708862,676.334595 441.614685,671.166077 441.768646,666.005066 
	C441.851807,663.216309 440.477814,659.512390 444.681305,658.496643 
	C448.323975,657.616394 452.029694,657.529053 453.935883,661.613342 
	C457.704987,660.276672 461.172150,658.649109 464.820740,657.827759 
	C475.772400,655.362427 484.337097,661.608459 484.797943,672.656738 
	C485.193420,682.137451 484.800537,691.648621 484.916840,701.144409 
	C484.966644,705.209412 482.409729,705.235107 479.575317,705.071716 
	C476.760559,704.909302 473.150452,706.074890 473.171844,701.153809 
	C473.206635,693.156189 473.205109,685.157898 473.136200,677.160583 
	C473.081268,670.784668 470.407806,667.894226 464.719849,667.853882 
	C458.583984,667.810364 453.188629,672.299561 453.153564,677.565247 
	C453.102570,685.229614 452.943420,692.900024 453.183197,700.556824 
	C453.310455,704.622314 451.296570,705.271423 447.966736,705.181763 
	C444.656372,705.092529 441.484955,705.380249 441.637787,700.493958 
	C441.825256,694.501099 441.693146,688.498291 441.699463,681.999878 
z"/>
<path fill="#E9FCFC" opacity="1.000000" stroke="none" 
	d="
M661.769165,702.252075 
	C659.907288,697.569153 657.327393,693.208008 656.931763,688.656921 
	C656.199707,680.236694 656.878662,671.702637 656.708191,663.221252 
	C656.613953,658.537964 659.498657,658.348267 662.918518,658.201477 
	C666.688171,658.039612 668.356750,659.338928 668.218140,663.390137 
	C667.973633,670.534973 668.142639,677.695679 668.209595,684.848999 
	C668.279663,692.339478 670.963074,695.566406 676.950134,695.519287 
	C684.121704,695.462769 688.166870,691.589600 688.184326,684.750793 
	C688.201721,677.929260 688.410034,671.100098 688.152771,664.288757 
	C687.976929,659.630859 690.001648,658.211426 694.369873,658.114197 
	C698.817810,658.015320 700.094727,659.763428 700.006592,664.042358 
	C699.766785,675.683716 700.015076,687.334717 699.819458,698.977661 
	C699.785828,700.977661 698.566895,704.631409 697.815918,704.652283 
	C694.762024,704.737000 691.665222,703.755432 688.616089,703.032471 
	C688.125610,702.916138 687.797363,702.115784 687.311401,701.532227 
	C678.972839,706.466797 670.622986,708.539062 661.769165,702.252075 
z"/>
<path fill="#FAFEFE" opacity="1.000000" stroke="none" 
	d="
M625.733276,390.245758 
	C654.526794,417.918823 667.421570,451.636383 664.790100,490.891388 
	C663.105347,516.024170 654.216064,538.734131 638.229248,558.595520 
	C617.063232,584.891479 589.826233,600.610168 556.518860,604.705322 
	C520.234863,609.166626 487.707977,599.531128 459.678223,575.575256 
	C435.122498,554.588440 420.842133,527.876770 417.174591,496.070923 
	C412.873199,458.768311 423.244537,425.291107 448.560852,397.434723 
	C469.667908,374.209900 496.257629,360.580597 527.807373,357.319580 
	C562.370972,353.747040 592.869141,363.646393 619.935120,384.974884 
	C621.888794,386.514435 623.615967,388.341278 625.733276,390.245758 
M589.878723,530.124634 
	C593.485657,524.312378 593.229187,520.119751 588.995850,515.692200 
	C585.018433,511.532349 580.460876,511.148285 574.928772,513.806946 
	C568.389771,516.949524 561.857178,521.254395 554.934937,522.188416 
	C543.796509,523.691406 532.331604,523.186279 521.022705,522.874268 
	C512.995361,522.652771 508.990234,517.880310 508.970551,509.897156 
	C508.925812,491.737701 508.912811,473.578003 508.966034,455.418640 
	C508.991089,446.861298 513.750244,442.106628 522.258484,442.034027 
	C529.754883,441.970062 537.258972,442.227600 544.747681,441.980896 
	C555.595764,441.623505 565.371460,444.283783 574.296692,450.628174 
	C580.298767,454.894684 586.550598,453.633698 590.500183,448.067139 
	C594.091553,443.005432 593.156067,436.318726 587.562866,432.144592 
	C578.490601,425.374084 568.348999,420.446716 556.911743,419.972321 
	C538.618958,419.213470 520.295044,419.145447 501.982544,418.943146 
	C490.987579,418.821655 486.240448,423.688263 486.186981,434.799255 
	C486.136475,445.294891 486.169006,455.790985 486.176056,466.286865 
	C486.190643,487.944763 486.130707,509.603180 486.268341,531.260315 
	C486.330963,541.116333 491.387543,545.863281 501.250946,545.854248 
	C517.744385,545.839111 534.246826,546.140991 550.728516,545.689758 
	C565.253906,545.292114 578.436768,540.661011 589.878723,530.124634 
M561.525024,490.009766 
	C566.083923,484.156647 565.991760,477.360291 561.294678,473.023071 
	C556.518860,468.613129 549.250000,468.820831 544.681702,473.497681 
	C539.894470,478.398712 539.728149,484.490753 544.244385,489.514313 
	C549.036011,494.844208 554.911987,495.195038 561.525024,490.009766 
M599.476074,496.740601 
	C597.706726,500.118439 595.641418,503.384186 594.300964,506.924438 
	C593.689392,508.539581 594.508362,510.696350 594.678528,512.607483 
	C596.417969,511.856506 598.866516,511.600464 599.788757,510.279114 
	C610.826294,494.464905 610.796387,469.650421 599.711548,454.253906 
	C598.784790,452.966736 596.273743,452.820221 594.498779,452.143768 
	C594.414368,454.073730 593.517212,456.529449 594.372498,457.851501 
	C602.099243,469.795166 603.914734,482.436768 599.476074,496.740601 
M593.154968,483.332642 
	C592.872314,475.143677 590.813049,467.510345 585.367554,461.221649 
	C584.398621,460.102661 582.047180,460.180817 580.334045,459.706207 
	C580.536621,461.348236 580.171814,463.411896 581.030029,464.566650 
	C589.053589,475.362427 590.301758,487.855133 581.120361,499.968719 
	C580.219482,501.157349 580.783264,503.456055 580.667114,505.239471 
	C582.323792,504.619110 584.960876,504.441528 585.474304,503.304810 
	C588.298950,497.050903 590.604431,490.562469 593.154968,483.332642 
M573.125000,498.595062 
	C580.290771,487.284668 580.423401,475.518066 573.235901,466.635040 
	C572.310120,465.490875 570.320312,465.207703 568.823364,464.525665 
	C568.957336,465.976746 568.581116,467.782959 569.305237,468.823334 
	C575.695435,478.005066 575.415771,487.017944 569.268250,496.164703 
	C568.576782,497.193512 568.703186,498.772034 568.450378,500.095642 
	C569.857849,499.778442 571.265259,499.461243 573.125000,498.595062 
z"/>
<path fill="#0DACE9" opacity="1.000000" stroke="none" 
	d="
M334.050232,688.640991 
	C334.057312,676.537354 334.231171,664.889221 333.978088,653.250427 
	C333.880859,648.780701 335.566101,647.234131 339.792480,647.610840 
	C341.938019,647.802124 344.116638,647.583801 346.276581,647.658325 
	C359.730530,648.122559 368.707703,656.216858 369.771698,668.804932 
	C370.810272,681.091736 363.483521,690.547241 350.535309,693.349731 
	C347.138855,694.084900 343.631470,694.362671 340.159393,694.679138 
	C336.186127,695.041260 333.604553,693.674805 334.050232,688.640991 
z"/>
<path fill="#10ABE5" opacity="1.000000" stroke="none" 
	d="
M739.061646,695.677246 
	C736.518372,695.945312 734.364014,696.346436 732.242310,696.216187 
	C726.479553,695.862488 724.863525,693.981445 724.874146,688.115479 
	C724.882507,683.470825 725.031738,678.821228 724.863953,674.183044 
	C724.726013,670.368591 726.957214,668.844421 730.109497,668.070740 
	C737.483948,666.260803 743.740845,668.888611 746.544556,674.894775 
	C749.712891,681.681763 747.830017,689.869202 742.071045,694.145142 
	C741.284119,694.729309 740.337891,695.098999 739.061646,695.677246 
z"/>
<path fill="#10ABE5" opacity="1.000000" stroke="none" 
	d="
M619.217529,696.250244 
	C613.409485,695.875916 611.178955,693.153259 611.630676,687.700012 
	C612.000549,683.234253 611.337585,678.665527 611.902954,674.240967 
	C612.164124,672.197083 613.876099,669.288574 615.602112,668.681213 
	C620.533325,666.945862 625.782410,666.574524 630.139160,670.595154 
	C634.591125,674.703674 636.167053,681.704468 633.803833,687.628296 
	C631.647095,693.034485 627.725403,696.251099 621.655884,696.324097 
	C620.990173,696.332092 620.324097,696.307312 619.217529,696.250244 
z"/>
<path fill="#32AAD4" opacity="1.000000" stroke="none" 
	d="
M408.012146,696.613953 
	C406.290344,696.492493 404.679962,696.794434 403.720642,696.140808 
	C402.087128,695.027771 400.062836,693.480652 399.713196,691.801453 
	C399.419556,690.391235 401.018341,687.357727 402.231110,687.090027 
	C406.100708,686.236023 410.192505,686.090149 414.169312,686.280579 
	C414.934998,686.317261 416.202576,689.163086 416.096436,690.630371 
	C415.973663,692.327576 414.964325,694.422729 413.643036,695.439148 
	C412.360199,696.425842 410.194427,696.264648 408.012146,696.613953 
M402.508698,690.001465 
	C402.918640,693.659241 406.217834,695.750488 410.209961,694.316223 
	C411.672577,693.790771 412.598328,691.770935 413.768982,690.432739 
	C412.166351,689.310303 410.612427,687.377625 408.948334,687.277588 
	C407.014893,687.161316 404.984833,688.651917 402.508698,690.001465 
z"/>
<path fill="#10ABE5" opacity="1.000000" stroke="none" 
	d="
M560.919800,695.514038 
	C551.118652,692.041077 550.101013,683.650452 551.365356,677.755493 
	C552.724731,671.417053 559.517151,667.202759 566.680054,667.804504 
	C573.593323,668.385254 578.198364,674.193787 578.076721,682.179443 
	C577.963379,689.619873 572.987244,695.375183 566.286682,695.727234 
	C564.633301,695.814209 562.967346,695.662476 560.919800,695.514038 
z"/>
<path fill="#0DACE9" opacity="1.000000" stroke="none" 
	d="
M589.646057,530.420288 
	C578.436768,540.661011 565.253906,545.292114 550.728516,545.689758 
	C534.246826,546.140991 517.744385,545.839111 501.250946,545.854248 
	C491.387543,545.863281 486.330963,541.116333 486.268341,531.260315 
	C486.130707,509.603180 486.190643,487.944763 486.176056,466.286865 
	C486.169006,455.790985 486.136475,445.294891 486.186981,434.799255 
	C486.240448,423.688263 490.987579,418.821655 501.982544,418.943146 
	C520.295044,419.145447 538.618958,419.213470 556.911743,419.972321 
	C568.348999,420.446716 578.490601,425.374084 587.562866,432.144592 
	C593.156067,436.318726 594.091553,443.005432 590.500183,448.067139 
	C586.550598,453.633698 580.298767,454.894684 574.296692,450.628174 
	C565.371460,444.283783 555.595764,441.623505 544.747681,441.980896 
	C537.258972,442.227600 529.754883,441.970062 522.258484,442.034027 
	C513.750244,442.106628 508.991089,446.861298 508.966034,455.418640 
	C508.912811,473.578003 508.925812,491.737701 508.970551,509.897156 
	C508.990234,517.880310 512.995361,522.652771 521.022705,522.874268 
	C532.331604,523.186279 543.796509,523.691406 554.934937,522.188416 
	C561.857178,521.254395 568.389771,516.949524 574.928772,513.806946 
	C580.460876,511.148285 585.018433,511.532349 588.995850,515.692200 
	C593.229187,520.119751 593.485657,524.312378 589.646057,530.420288 
z"/>
<path fill="#15ACE4" opacity="1.000000" stroke="none" 
	d="
M561.250122,490.261536 
	C554.911987,495.195038 549.036011,494.844208 544.244385,489.514313 
	C539.728149,484.490753 539.894470,478.398712 544.681702,473.497681 
	C549.250000,468.820831 556.518860,468.613129 561.294678,473.023071 
	C565.991760,477.360291 566.083923,484.156647 561.250122,490.261536 
z"/>
<path fill="#27A8DA" opacity="1.000000" stroke="none" 
	d="
M599.603882,496.364471 
	C603.914734,482.436768 602.099243,469.795166 594.372498,457.851501 
	C593.517212,456.529449 594.414368,454.073730 594.498779,452.143768 
	C596.273743,452.820221 598.784790,452.966736 599.711548,454.253906 
	C610.796387,469.650421 610.826294,494.464905 599.788757,510.279114 
	C598.866516,511.600464 596.417969,511.856506 594.678467,512.607483 
	C594.508362,510.696350 593.689392,508.539581 594.300964,506.924438 
	C595.641418,503.384186 597.706726,500.118439 599.603882,496.364471 
z"/>
<path fill="#27A8DA" opacity="1.000000" stroke="none" 
	d="
M593.121521,483.743591 
	C590.604431,490.562469 588.298950,497.050903 585.474304,503.304810 
	C584.960876,504.441528 582.323792,504.619110 580.667114,505.239502 
	C580.783264,503.456055 580.219482,501.157349 581.120361,499.968719 
	C590.301758,487.855133 589.053589,475.362427 581.030029,464.566650 
	C580.171814,463.411896 580.536621,461.348236 580.334106,459.706207 
	C582.047180,460.180817 584.398621,460.102661 585.367554,461.221649 
	C590.813049,467.510345 592.872314,475.143677 593.121521,483.743591 
z"/>
<path fill="#32AAD4" opacity="1.000000" stroke="none" 
	d="
M572.898865,498.869568 
	C571.265259,499.461243 569.857849,499.778442 568.450378,500.095642 
	C568.703186,498.772034 568.576782,497.193512 569.268250,496.164703 
	C575.415771,487.017944 575.695435,478.005066 569.305237,468.823334 
	C568.581116,467.782959 568.957336,465.976746 568.823364,464.525696 
	C570.320312,465.207703 572.310120,465.490875 573.235901,466.635040 
	C580.423401,475.518066 580.290771,487.284668 572.898865,498.869568 
z"/>
<path fill="#0DACE9" opacity="1.000000" stroke="none" 
	d="
M402.752686,689.723267 
	C404.984833,688.651917 407.014893,687.161316 408.948334,687.277588 
	C410.612427,687.377625 412.166351,689.310303 413.768982,690.432739 
	C412.598328,691.770935 411.672577,693.790771 410.209961,694.316223 
	C406.217834,695.750488 402.918640,693.659241 402.752686,689.723267 
z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
